import cn from 'clsx'
import { FC } from 'react'

const InputTypeOption: FC<{
  title?: string
  options: Array<any>
  onClick?: (text: string) => void
  className?: string
}> = ({ title, options, onClick, className }) => {
  return (
    <select
      className={cn(
        'appearance-none transition duration-150 ease-in-out',
        'border border-black border-opacity-25 rounded-lg',
        'focus:outline-none focus:shadow-outline-normal',
        'bg-primary p-2 w-full text-slate-400',
        'text-xs sm:text-sm md:text-sm lg:text-base',
        className
      )}
      onChange={(ev) => {
        const option = options.find(
          (option) => option.value === ev.target.value
        )
        onClick && onClick(JSON.stringify(option))
      }}
      defaultValue=''
    >
      <option key='0' value='' hidden>
        {title || 'Pilih salah satu'}
      </option>
      {options?.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  )
}

export default InputTypeOption
